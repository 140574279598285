import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import { header } from '../customjs/header.js';
import { home } from '../customjs/home.js';
import { team } from '../customjs/team.js';
import { cultureSlider } from '../customjs/cultureslider.js';
import { careers } from '../customjs/careers.js';
import { horizontalScroller } from '../customjs/horizontalScroller.js';
import { services } from './services.js';

var $ = jQuery;

const { gsap } = GSAP;

$(document).ready(function($) {
    commonCell();
    pagesFunction($);
    header($);
    // tell Barba to use the prefetch plugin
    barba.use(barbaPrefetch);
    // Barba Start
    barba.init({
        debug: true,
        transitions: [{

                leave(data) { // When page leaves from normal anchor click
                    $('.transition--circle .pivot-circle-inner').hide();
                    const done = this.async();
                    document.clear();
                    pageOut(done);
                },
                enter() { // When page enters from normal anchor click
                    $('body').removeClass('firstload');
                    // setTimeout(function() {
                    //
                    //     scrollTop(0);
                    // }, 1000);
                    $('body').removeAttr('style');
                    $('body').removeClass('block');

                    commonCell();
                    setTimeout(function() {
                        pagesFunction($);
                    }, 500);
                    setTimeout(function() {
                        pageIn();
                    }, 600);

                },
            },
            { // When page leaves from bottom footer anchor click
                custom: ({
                    trigger
                }) => trigger.classList && trigger.classList.contains('footer'),
                leave(data) {
                    const done = this.async();
                    document.clear();
                    pageOutFooter(done);
                },
                enter() {
                    setTimeout(function() {
                        scrollTop(0);
                    }, 1000);
                    $('body').removeClass('firstload');
                    $('body').removeClass('block');
                    $('body').removeAttr('style');
                    commonCell();
                    setTimeout(function() {
                        pagesFunction($);
                    }, 500);
                    setTimeout(function() {
                        pageInFooter();
                    }, 600);
                },
            }
        ],
    });
    // Barba End

    cirlce();
    $(window).resize(function() {
        cirlce();
    });

    $(".footer--tabs button").on('click', function() {
        $(".footer--tabs button").removeClass('green')
        $(this).addClass('green')

        $('.footer--tabs-address').removeClass('active')
        $(`#footer-${$(this).data('location')}`).addClass('active')
    });

    $('[data-path]').each(function () {
        var totPaths = parseInt($(this).attr('data-path'), 10),
          i = 0;
        for (var i = 1; i < totPaths + 1; i++) {
          $(this).append('<span class="path' + i + '"></span>');
        }
      });
});

function pagesFunction($) { // Functions to run on page load
    $('.footer').show();
    $('header').removeClass('absolute white');

    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'home') {
        home($, gsap);
        // scroller($);
        logoColour('white');
        $('footer').hide();
        $('header').addClass('absolute');
    }
    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'team') {
        team($);
        //  transitionModal($);
        logoColour('black');
        // cultureSlider($);
    }
    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'work') {
        //scroller($);
        logoColour('black');
    }
    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'services') {
        logoColour('black');
        services($);
    }
    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'about-us') {
        logoColour('black');
        horizontalScroller($);
    }
    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'contact') {
        logoColour('black');
    }
    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'careers') {
        logoColour('black');
        cultureSlider($);
        careers($);
        accordion();
    }
    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'work-detail') {
        logoColour('black');
        $('footer').hide();
    }

    if ($('[data-barba-namespace]').attr('data-barba-namespace') === 'career-detail') {
        logoColour('black');
        $('footer').hide();
    }

    // common functions

}

function commonCell() {
    if ($(window).width() > 768) {
        $('.common--cell, .rounded--circle-dotted').css('height', $(window).height());
    } else {
        $('.common--cell, .rounded--circle-dotted').css('height', $(window).width());
    }
    $('.rounded--cirlce-big').addClass('right');
    $("a").on('click', function(event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function() {
                window.location.hash = hash;
            });
        } // End if
    });
}

function pageOut(done) { // When you leave a page
    // if ($('.menu--desktop').hasClass('open')) { // To reset the menu
    //     $('.hamburger').removeClass('open');
    //     $('.menu--desktop').removeClass('open');
    //     TweenMax.to($('.menu--desktop'), 0.3, {
    //         x: '-100%',
    //         ease: Power3.easeInOut,
    //         onComplete: function() {
    //             TweenMax.to($('.pivot-outlined'), 0.5, {
    //                 x: '100%',
    //                 ease: Power3.easeInOut
    //             })
    //         }
    //     });
    //     TweenLite.to($('#menu-morph'), 0.6, {
    //         morphSVG: $('#morph-shape').attr('data-morph-return'),
    //         ease: Power3.easeInOut
    //     });
    // }

    // To make the circle small again
    TweenMax.to('.transition--circle', 0.3, {
        background: '#CDD32C',
        width: '300vw',
        height: '300vw',
        onComplete: function() {
            document.clear();
            setTimeout(function() {
                done();
            }, 200);
            $('header').removeAttr('style');
        }
    });
}

function pageOutFooter(done) { // When you leave a page from footer
    TweenMax.to('.transition--footer', 0.5, {
        y: '0%',
        onComplete: function() {
            document.clear();
            done();
            $('header').removeAttr('style');
        }
    });
}

function pageIn() { // When you enter a new page
    preLoaderHide();
    scrollTop(0);
    TweenMax.to("#app, footer", 0.1, { x: 0, ease: Power3.easeOut })
    $('.menu--desktop, .menu--desktop_inner').removeAttr('style');
}

function pageInFooter() { // When you enter a new page from the footer links

    TweenMax.to('.transition--footer', 0.5, {
        y: '-100%',
        onComplete: function() {
            firstLoadAnims();
            $('.transition--footer').css({
                transform: 'translateY(100%)'
            });
            $('.transition--footer').remove();
            $('body').append('<div class="transition--footer"></div>');
        }
    });
    scrollTop(0);
    TweenMax.to("#app, footer", 0.1, { x: 0, ease: Power3.easeOut })
    $('.menu--desktop, .menu--desktop_inner').removeAttr('style');
}

function preLoaderHide() { // Hide first load loader
    if ($('[data-barba-namespace]').attr('data-barba-namespace') == 'home' && $('body').hasClass('firstload')) {
        // For homepage if first time load from refresh
        setTimeout(function() {
            TweenMax.to('.transition--circle', 0.3, {
                background: 'transparent'
            });
            TweenMax.to('.transition--circle .pivot-circle-inner', 0.3, {
                width: 0,
                height: 0,
                onComplete: function() {
                    $('.transition--circle').css({
                        width: 0,
                        height: 0,
                    });
                    firstLoadAnims();
                }
            });
        }, 5000);
    } else { // for normal transition of circle if navigating
        TweenMax.to('.transition--circle', 0.3, {
            width: 0,
            height: 0,
            onComplete: function() {
                setTimeout(function() {
                    firstLoadAnims();
                }, 100);
            }
        });
    }
}

function firstLoadAnims() { // animations on first load of the page
    // grow the transition circle
    TweenMax.to('.pivot-circle', 0.8, {
        width: $(window).width() > 500 ? '50vw' : $(window).width() <= 375 ? '80vw' : '75vw',
        height: $(window).width() > 500 ? '50vw' : $(window).width() <= 375 ? '80vw' : '75vw',
        autoAlpha: 1,
        ease: Back.easeOut.config(1),
        onComplete: function() {
            $('.home--wrapper .pivot-circle').removeClass('oh');
            TweenMax.to('.banner--image', 0.8, {
                opacity: 1
            });
        }
    });

    // show banner transitions
    TweenMax.to('.pivot--box', 1, {
        x: '0',
        autoAlpha: 1,
        delay: 0.4,
        ease: Power3.easeInOut
    });
    TweenMax.to('.banner--text h2', 1, {
        x: '0',
        autoAlpha: 1,
        delay: 0.6,
        ease: Power3.easeInOut
    });

    var worksDetailPreviewtitle = $('.works--detail-previewtitle'),
        worksDetailClientlogo = $('.works--detail-clientlogo'),
        worksDetailTaglist = $('.works--detail-taglist'),
        worksDetailPreviewbtn = $('.works--detail-previewbtn');
    TweenMax.to(worksDetailClientlogo, 0.5, {
        y: 0,
        ease: Power4.easeOut,
        autoAlpha: 1
    });
    TweenMax.to(worksDetailPreviewtitle, 1, {
        y: 0,
        ease: Expo.easeOut,
        autoAlpha: 1,
        delay: 0.3
    });
    TweenMax.staggerTo(worksDetailTaglist, 1.6, {
        y: 0,
        ease: Power4.easeOut,
        autoAlpha: 1
    }, 0.5);
    TweenMax.staggerTo(worksDetailPreviewbtn, 2.5, {
        y: 0,
        ease: Power4.easeOut,
        autoAlpha: 1
    }, 2.5);

    // delay attribute elements add transition delay
    $('[tr-delay]').each(function() {
        var delay = $(this).attr('tr-delay');
        $(this).css('transition-delay', delay);
    });
}

function logoColour(colour) { // set logo colour according to page
    if (colour == 'black') {
        TweenMax.set('.logo--wrapper img', {
            attr: {
                src: "../images/logo_black.svg"
            }
        });
        TweenMax.set('.hamburger span', {
            backgroundColor: '#000'
        });
    } else if (colour == 'white') {
        TweenMax.set('.logo--wrapper img', {
            attr: {
                src: "../images/logo_white.svg"
            }
        });
        TweenMax.set('.hamburger span', {
            backgroundColor: '#fff'
        });
    }
}

function cirlce() {
    TweenMax.to(".planet-dotted", 1, {
        strokeDashoffset: "-=12",
        repeat: -1,
        ease: Linear.easeNone
    });
}

// on complete page load first time
var loaderInt;
$(window).on('load', function() {
    preLoader();
    loaderInt = setInterval(function() {
        if (!$('body').hasClass('loaded')) {
            $('body').addClass('loaded firstload');
            console.log('loaded');
            if ($('body').hasClass('loaded')) {
                preLoaderHide();
                clearInterval(loaderInt);
                scrollTop(0);
                // setTimeout(function() {
                //     $('.cursor').css('opacity', '1');
                // }, 1000);
            }
        }
    }, 500);
});

function scrollTop(scrollDistance) { // animate on hash link
    var hash = location.hash;
    var hash = location.hash.substr(1);
    console.log(hash);
    if (hash !== '' && $('#' + hash).length) {
        $('html, body').stop().animate({
            scrollTop: $('#' + hash).offset().top
        }, 1000);
    } else {
        $(window).scrollTop(scrollDistance);
    }
}

function accordion() { // career page accordions
    var scrollTO;
    $('.accordion--container li .accordion-header button').on('click', function() {
        var $this = $(this);
        if ($this.parents('li').hasClass('open')) {
            $this.parents('li').removeClass('open');
            $this.parents('li').find('.hidden-data').slideUp(300);
            $this.addClass('pr--plus').removeClass('pr--minus');
        } else {
            $('.accordion--container li .hidden-data').slideUp(300);
            $('.accordion--container li').removeClass('open');
            $('.accordion--container li .accordion-header button').addClass('pr--plus').removeClass('pr--minus');
            $this.parents('li').addClass('open');
            $this.parents('li').find('.hidden-data').slideDown(300);
            $this.addClass('pr--minus').removeClass('pr--plus');
            scrollTO = setTimeout(function() {
                $('html, body').stop().animate({
                    scrollTop: $this.parents('li').offset().top
                }, 600);
                clearTimeout(scrollTO);
            }, 500);
        }
    });
}

function preLoader() { // set which preloader to show
    if ($('[data-barba-namespace]').attr('data-barba-namespace') == 'home' && !$('body').hasClass('loaded')) {
        $('.transition--circle').removeClass('other').addClass('home');

    } else {
        $('.transition--circle').removeClass('home').addClass('other');
    }
}
