export function services($) {

    const swiperMarquee = new Swiper.default('#service--carrousel-wrapper', {
        spaceBetween: 10,
        speed: 600,
        slidesPerView: 1.1,
        loop: true,
        breakpoints: {
          640: {
            slidesPerView: 2.15,
          }
        }
    });

}
