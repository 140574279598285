//jQuery
window.jQuery = require('jquery');

//GSAP
window.TweenMax = require('./vendorjs/TweenMax');
// require('./vendorjs/MorphSVGPlugin');

//GSAP
window.GSAP = require('./vendorjs/gsap');

//Underscore
window._ = require('underscore');

//Parallax
window.Parallax = require('parallax-js');


//Swiper
window.Swiper = require('swiper');

// Our JS
require('./customjsmobile/main-mobile');
